@import url("https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,400;0,500;0,600;1,300&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://cdnjs.cloudflare.com/ajax/libs/flowbite/2.3.0/flowbite.min.css');

body {
  @apply leading-[normal] m-0;
}

/* Allow for full height elements. */
html, body, div#root {
  height: 100%;
}

*,
::before,
::after {
  border-width: 0;
}

.no-scrollbar::-webkit-scrollbar,
body::-webkit-scrollbar{
   display:none !important;
}

/* Reduce size on dev errors overlay */
iframe#webpack-dev-server-client-overlay {
  height: 33vh !important;
  bottom: 0px !important;
  position: sticky !important;
}

.max-z-index{
  z-index: 10000000000;
}

h5 {
  margin-block-start: 1rem;
  margin-block-end: 1rem;
}
